<template>
  <div>
    <b-modal
      id="modal_actividad_taller"
      :hide-footer="true"
      size="xl"
      :title="tituloModal"
      no-close-on-backdrop
      :header-bg-variant="esGuardar ? 'primary' : 'warning'"
    >
      <template
        #modal-header
      >
        <span class="text-white">
          <strong class="float-left">
            {{ tituloModal }}
          </strong>
        </span>
        <span class="float-right">
          <b-button
            variant="gradient-danger"
            @click="cerrarModal = true; formSubmitted(); $bvModal.hide('modal_actividad_taller')"
          >
            <feather-icon
              icon="XIcon"
            />
          </b-button>
        </span>

      </template>

      <div v-if="cerrarModal === false">
        <form-wizard
          next-button-text="Guardar y siguiente"
          back-button-text="Anterior"
          color="#06634c"
          :title="null"
          :subtitle="null"
          layout="horizontal"
          finish-button-text="Terminar Proceso"
          class="wizard-horizontal mb-3"
          @on-complete="formSubmitted"
          @on-change="onchange"
        >
          <tab-content
            title="Crear Actividad"
            :before-change="validationFormInfo"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    {{ codigo === 'OCADE-ALT-COMP' ? 'Registrar nuevo entrenamiento' : 'Registro de nueva actividad' }}
                  </h5>
                  <small class="text-muted">
                    {{ codigo === 'OCADE-ALT-COMP' ? 'Detalle del entrenamiento' : 'Detalle la actividad a crear' }}
                  </small>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    label="Actividad"
                    label-for="v-actividad"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="actividad"
                      rules="required"
                    >
                      <b-form-input
                        id="v-actividad"
                        v-model="actividad.nombre"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                      />
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >El campo nombre es obligatorio</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group

                    label="Fecha"
                    label-for="v-fecha"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="fecha"
                      rules="required"
                    >
                      <b-form-input
                        id="v-fecha"
                        v-model="actividad.fecha"
                        type="date"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >el campo fecha es obligatorio</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group

                    label="Descripción (opcional)"
                    label-for="v-descripcion"
                  >
                    <b-form-textarea
                      id="v-descripcion"
                      v-model="actividad.descripcion"
                      placeholder="Describa aquí"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content title="Estudiantes">
            <b-row>
              <b-col
                cols="6"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ codigo === 'OCADE-ALT-COMP' ? 'Estudiantes' : 'Estudiantes' }}
                </h5>
                <!-- <small class="text-muted">{{ codigo === 'OCADE-ALT-COMP' ? 'Participantes' : 'Estudiantes' }}</small> -->
              </b-col>
              <b-col
                cols="6"
                class="my-1 float-right"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Buscar por DNI o Apellidos y nombres"
                    />

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        size=""
                        @click="filter = ''"
                      >
                        Limpiar
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="selected"
                  checked
                  name="flavour-2"
                >
                  <b-table
                    :items="arrayEstudiantes"
                    :fields="fieldEstudiantes"
                    :filter="filter"
                    sticky-header="380px"
                    small
                    head-variant="light"
                  >
                    <template #cell(actions)="row">
                      <b-form-checkbox
                        switch
                        :value="row.item.usuario_id"
                        name="check"
                      />
                    </template>
                    <template #cell(numero_documento)="row">
                      {{ row.item.persona_alumno.numero_documento }}
                    </template>
                    <template #cell(apellido_paterno)="row">
                      {{ row.item.persona_alumno.apellido_paterno }}
                    </template>
                    <template #cell(apellido_materno)="row">
                      {{ row.item.persona_alumno.apellido_materno }}
                    </template>
                    <template #cell(nombres)="row">
                      {{ row.item.persona_alumno.nombres }}
                    </template>
                    <template #cell(pueblo_indigena)="row">
                      {{ row.item.persona_alumno.pueblo_indigena.nombre }}
                    </template>

                  </b-table>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </tab-content>
          <!-- address -->
          <tab-content
            title="Evidencias"
            :before-change="validationFormInfo"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Evidencias
                </h5>
                <small class="text-muted">Registro de evidencias</small>
              </b-col>
              <b-col md="5">
                <b-form-group
                  label="Nombre Evidencia"
                  label-for="nueva_evidencia"
                >
                  <b-form-input
                    id="nueva_evidencia"
                    v-model="evidenciaNueva.nombre"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Archivo"
                  label-for="archivo"
                >
                  <b-form-file
                    v-model="evidenciaNueva.archivo"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-button
                  size="sm"
                  variant="success"
                  class="mt-2"
                  @click="adicionarEvidencia()"
                >
                  <feather-icon icon="ArrowUpCircleIcon" />
                  Cargar archivo
                </b-button>
              </b-col>
              <b-col md="12">
                <b-table
                  :items="evidencias"
                  :fields="fieldEvidencias"
                  sticky-header="300px"
                  small
                  head-variant="light"
                  class="mb-3"
                >
                  <template #cell(actions)="row">
                    <b-button
                      size="sm"
                      class="mr-1"
                      variant="outline-danger"
                      @click="eliminarEvidencia(row.item.id)"
                    >
                      eliminar
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </template>
                  <template #cell(archivo)="row">
                    <a
                      :href="row.item.archivo"
                      class="btn btn-sm btn-outline-success"
                      target="_blank"
                    >ver evidencia
                      <feather-icon icon="EyeIcon" />
                    </a>
                  </template>
                </b-table>
                <br>
                <br>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  VBModal,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BInputGroupAppend,
  BInputGroup,
  BFormFile,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BTable,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormCheckbox,
    BFormCheckboxGroup,
    FeatherIcon,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    codigo: {
      type: String,
      default: '#',
    },

  },
  data() {
    return {
      selected: [],
      nombre_taller: '',
      estado_taller: '',
      taller_id: this.$attrs.taller_id,
      actividad_id: 0,
      evidenciaNueva: {
        nombre: '',
        archivo: null,
      },
      arrayEstudiantes: [],
      arrayAsistencia: [],

      fieldEvidencias: [
        {
          key: 'nombre', label: 'Nombre', sortable: true, sortDirection: 'asc',
        },
        {
          key: 'archivo', label: 'Evidencia', sortable: true, sortDirection: 'asc',
        },
        {
          key: 'actions', label: 'Eliminar', sortable: true, sortDirection: 'asc',
        },
      ],
      fieldEstudiantes: [
        {
          key: 'numero_documento', label: 'DNI', sortable: true, sortDirection: 'asc',
        },
        {
          key: 'apellido_paterno', label: 'Ap.Paterno', sortable: true, sortDirection: 'asc',
        },
        {
          key: 'apellido_materno', label: 'Ap.Materno', sortable: true, sortDirection: 'asc',
        },
        {
          key: 'nombres', label: 'Nombres', sortable: true, sortDirection: 'asc',
        },
        {
          key: 'pueblo_indigena', label: 'Pueblo Indigena', sortable: true, sortDirection: 'asc',
        },
        { key: 'actions', label: 'Asistencia' },
      ],
      filter: null,
      // estudiantes: [],
      required,
      actividad: {
        id: '',
        nombre: '',
        fecha: '',
        descripcion: '',
      },
      /// ///////////////////////////////////////
      tituloModal: '',
      evidencias: [],
      esGuardar: true,
      cerrarModal: false,
      // startIndex: 0,
    }
  },
  created() {
    this.$parent.$on('nuevaActividad', this.nuevaActividad)
    this.$parent.$on('editarActividad', this.editarActividad)
  },
  methods: {
    nuevaActividad() {
      // this.startIndex = 0
      this.tituloModal = this.codigo === 'OCADE-ALT-COMP' ? 'Nuevo entrenamiento' : 'Nueva actividad'
      this.$bvModal.show('modal_actividad_taller')
      this.esGuardar = true
      this.cerrarModal = false
      this.actividad.nombre = ''
      this.actividad.fecha = ''
      this.actividad.descripcion = ''
      this.arrayEstudiantes = []
      this.evidencias = []
      this.selected = []
      this.evidencias = []
    },
    editarActividad(item) {
      // this.startIndex = 0

      this.tituloModal = this.codigo === 'OCADE-ALT-COMP' ? 'Actualizar entrenamiento' : 'Actualizar actividad'
      this.actividad_id = item.id
      this.actividad.nombre = item.nombre
      this.actividad.fecha = item.fecha
      this.actividad.descripcion = item.descripcion
      this.$bvModal.show('modal_actividad_taller')
      this.esGuardar = false
      this.cerrarModal = false
      this.selected = []
      this.evidencias = []

      this.recuperarAsistencia()
      this.actualizarTablaEvidencias()
    },
    adicionarEvidencia() {
      if (this.evidenciaNueva.nombre === '' || this.evidenciaNueva.archivo === null) {
        this.toast('Campo nombre o archivo vacio ', 'warning', '¡Advertencia!')
        return
      }
      const formData = new FormData()
      // formData.append('id', this.actividad_id)
      formData.append('nombre', this.evidenciaNueva.nombre)
      formData.append('archivo', this.evidenciaNueva.archivo)
      const data = {
        codigo: this.codigo,
        actividadId: this.actividad_id,
        formData,
      }
      store.dispatch('actividades-taller-store-module/addEvidencia', data).then(() => {
        this.evidenciaNueva.nombre = ''
        this.evidenciaNueva.archivo = null
        this.toast('¡Evidencia registrada!', 'success', 'Éxito')
        this.actualizarTablaEvidencias()
      }).catch(() => {
        this.toast('¡Error al cargar evidencia!', 'danger', 'Error')
      })
    },
    actualizarTablaEvidencias() {
      const data = {
        codigo: this.codigo,
        actividadId: this.actividad_id,
      }
      store.dispatch('actividades-taller-store-module/getEvidencias', data).then(res => {
        this.evidencias = res.data
      }).catch(() => {
        this.toast('¡Error al actualizar tabla evidencias!', 'danger', 'Error')
      })
    },
    eliminarEvidencia(id) {
      const data = {
        codigo: this.codigo,
        actividadId: this.actividad_id,
        id,
      }
      store.dispatch('actividades-taller-store-module/deleteEvidencia', data).then(res => {
        this.swalSuccess(`Evidencia ${res.data.nombre} eliminada`)
        this.actualizarTablaEvidencias()
      }).catch(() => {
        this.toast('¡Error al eliminar evidencia seleccionada!', 'danger', 'Error')
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    toast(text, tipo, titulo) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titulo,
          icon: 'BellIcon',
          text,
          variant: tipo,
        },
      })
    },
    formSubmitted() {
      if (this.cerrarModal) { return }
      if (this.esGuardar === 0) {
        this.toast('¡Actividad guardada con exito!', 'success', 'Éxito')
      } else {
        this.toast('¡Actividad actualizada con exito!', 'success', 'Éxito')
      }
      this.$bvModal.hide('modal_actividad_taller')
      this.cerrarModal = true
    },
    swalSuccess(texto) {
      this.$swal({
        position: 'top-end',
        icon: 'success',
        title: texto,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    onchange(prevIndex) {
      if (this.cerrarModal) { return }
      switch (prevIndex) {
        case 0:
          if (this.esGuardar) {
            this.guardarActividad()
          } else {
            this.actualizarActividad()
          }
          // this.textoBotonNext = ''
          break
        case 1: this.actualizarAsistencia()
          break
        case 2:
          break
        default:
          break
      }
    },
    guardarActividad() {
      const data = {
        codigo: this.codigo,
        globalId: this.$route.params.globalId,
        tallerId: this.$route.params.id,
        data: this.actividad,
      }
      store.dispatch('actividades-taller-store-module/addActividadTaller', data).then(response => {
        this.esGuardar = false
        this.actividad_id = response.data.id
        this.recuperarAsistencia()
        this.$emit('actualizarTablaActividades')
      }).catch(() => {
        this.toast('Se recomienda actualizar el navegador', 'danger', 'Error en guardar actividad')
      })
    },
    actualizarActividad() {
      const data = {
        codigo: this.codigo,
        globalId: this.$route.params.globalId,
        tallerId: this.$route.params.id,
        id: this.actividad_id,
        data: this.actividad,
      }
      store.dispatch('actividades-taller-store-module/updateActividad', data).then(() => {
        this.$emit('actualizarTablaActividades')
      }).catch(() => {
        this.toast('Se recomienda actualizar el navegador', 'danger', 'Error en actualizar actividad')
      })
    },
    recuperarAsistencia() {
      const data = {
        codigo: this.codigo,
        globalId: this.$route.params.globalId,
        tallerId: this.$route.params.id,
      }
      store.dispatch('actividades-taller-store-module/getEstudiantes', data).then(res => {
        this.arrayEstudiantes = res.data
        const data2 = {
          codigo: this.codigo,
          actividadId: this.actividad_id,
        }
        store.dispatch('actividades-taller-store-module/getAsistenciaEstudiantes', data2).then(res2 => {
          this.arrayAsistencia = res2.data
          if (this.arrayAsistencia !== undefined) {
            this.arrayAsistencia.forEach(value => {
              this.arrayEstudiantes.forEach(value2 => {
                if (value.usuario_id === value2.usuario_id) {
                  this.selected.push(value.usuario_id)
                }
              })
            })
          }
        })
      }).catch(() => {
        this.toast('', 'danger', 'Error en obtener estudiantes')
      })
    },
    actualizarAsistencia() {
      const data = {
        codigo: this.codigo,
        actividadId: this.actividad_id,
        data: { usuarios_id: this.selected },
      }

      store.dispatch('actividades-taller-store-module/updateAsistenciaEstudiantes', data).catch(() => {
        this.toast('', 'danger', 'Error en actualizar asistencia')
      })
    },
  },

}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
 .wizard-icon-container{
   background-color: red;
 }
</style>
