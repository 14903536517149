import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getActividades(ctx, {
      codigo, globalId, id, queryParams,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-cultural-deportivo/${codigo}/global/${globalId}/taller/${id}/actividad`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addActividadTaller(ctx, {
      codigo, globalId, tallerId, data,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-cultural-deportivo/${codigo}/global/${globalId}/taller/${tallerId}/actividad`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEstudiantesAsistencia(ctx, { codigo, actividadId, data }) { // FALTA
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/participantes`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateActividad(ctx, {
      codigo, globalId, tallerId, id, data,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/servicio-cultural-deportivo/${codigo}/global/${globalId}/taller/${tallerId}/actividad/${id}`, data)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    deleteActividad(ctx, {
      codigo, globalId, tallerId, id,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-cultural-deportivo/${codigo}/global/${globalId}/taller/${tallerId}/actividad/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    /* estudiantes */

    getEstudiantes(ctx, { codigo, globalId, tallerId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-cultural-deportivo/${codigo}/global/${globalId}/taller/${tallerId}/matricula?sin_paginar=true`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAsistenciaEstudiantes(ctx, { codigo, actividadId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/participantes`)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    updateAsistenciaEstudiantes(ctx, { codigo, actividadId, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/participantes`, data)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    /* evidencias */
    getEvidencias(ctx, { codigo, actividadId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/evidencias`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvidencia(ctx, { codigo, actividadId, formData }) {
      // const config = { headers: { 'Content-Type': 'multipart/formdata' } }
      return new Promise((resolve, reject) => {
        axios
          .post(`servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/evidencias`, formData)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    deleteEvidencia(ctx, { codigo, actividadId, id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/evidencias/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTaller(ctx, { codigo, globalId, id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-cultural-deportivo/${codigo}/global/${globalId}/taller/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
